.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-toolbar *:disabled {
  cursor: not-allowed !important;
  color: grey !important;
}

.ql-container {
  height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.text-editor .ql-toolbar button {
  display: flex !important;
  width: auto !important;
}

.text-editor:fullscreen,
.text-editor:fullscreen .quill,
.text-editor:fullscreen .ql-container {
  height: 100%;
}

.text-editor.error .ql-toolbar,
.text-editor.error .ql-container {
  border-color: #f44336;
}
