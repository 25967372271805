.html-render-container {
  height: 300px;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.emailTemplate {
  width: 100%;
}
